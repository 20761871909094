import React from 'react';
import { Link } from 'react-router-dom';
import SplashFooter from '../common/footer/SplashFooter';
import SplashHeader from '../common/header/SplashHeader';
import SEO from '../common/SEO';
import SplashBanner from '../component/banner/SplashBanner';
import SplashCta from '../component/cta/SplashCta';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SplashData from '../data/splash/SplashData.json';
import { slugify } from '../utils';
import { FaAngleRight, FaEnvelope, FaHouseUser, FaLinkedinIn, FaPhone, FaReply, FaSteamSquare } from "react-icons/fa";

const FeatureData = SplashData[1];

const Splash = () => {

    return (
        <>
        <SEO title="Developed Expertise" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <SplashHeader />
                <SplashBanner />

                <div className="section section-padding main-demo-area bg-color-light" id="splash-services">
                <div className="container">
                        <div className="section-heading heading-light-left">
                            <div className="row align-items-center">
                                <div className="col-lg-6 services-title">
                                <h2 className="title">Microsoft-specialist</h2> <span>plus det løse</span>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                <p>Jeg er specialiseret i Microsoft-produkter med hovedfokus på SharePoint-platformen. </p>
                                <p>Med mine mange års erfaring kan jeg bistå i hele processen, fra rådgivning til udvikling og den tekniske dokumentation. </p><p>Nedenfor ses hvilke områder jeg blandt andet kan hjælpe med. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                FeatureData.slice(0, 9).map((data) => (
                                    <div className="col-xl-4 col-md-6" key={data.id}>
                                        <div className="services-grid">
                                            <div className="thumbnail">
                                                <img class="iconSpecial" src={process.env.PUBLIC_URL + data.icon} alt="icon" width={50} />
                                            </div>
                                            <div className="content">
                                                <h5 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h5>
                                                <p>{data.para}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {/* <div className="container">
                        <div className="section-heading heading-left">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-7">
                                <h2 className="title">Vi kan lidt af det meste</h2>
                                </div>
                                <div className="col-xl-4 col-lg-5 offset-xl-2">
                                <p>... men nedenstående er det vi er bedst til</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                DemoData.map((data) => (
                                <div className="col-md-6" key={data.id}>
                                    <div className="single-demo">
                                        <Link to={`${process.env.PUBLIC_URL}/${slugify(data.title)}`}>
                                            <span className="thumb">
                                                <img src={`${process.env.PUBLIC_URL}${data.height_img}`} alt={data.title} />
                                            </span>
                                            <h4 className="title">{data.title}</h4>
                                        </Link>
                                        
                                    </div>
                                </div>
                                ))
                            }

                        </div>
                    </div> */}
                    <ul className="shape-group list-unstyled">
                        <li className="shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-35.png"} alt="Shape" /></li>
                        <li className="shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Shape" /></li>
                    </ul>
                </div> 

                <div className="section section-padding bg-color-dark about-us" id="splash-about">
                <div className="container">
                        <div className="section-heading heading-light-left">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                <h2 className="title">Om CODALS</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <p>
                            CODALS startede op i 2019 som enkeltmandsfirma. Og det er det stadig. Efter mange år ansættelse i københavnske IT-konsulenthuse, tog hele familien vores ting og flyttede 
                            til Als i Sønderjylland. Tæt på vand og bedsteforældre og med masser af plads til bøgehæk og carport.
                            </p>
                            <p>Inkarneret computerfan siden 1990. Det er mit arbejde, min fritidsinteresse og min hobby.</p>
                            <p>
                                Når der ikke arbejdes, bruges tiden på familien, løb, brætspil, køkkenhaven, styrketræning, Krav Maga, Warhammer-figurspil og World of Warcraft. 
                                <br />
                                <br />Og så er der vist heller ikke tid til mere.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="section section-padding bg-color-dark splash-features" id="splash-contact">
                <div className="container">
                        <div className="section-heading heading-light-left">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                <h2 className="title">Kontaktinformation</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row contact-info">
                        <div className="col-lg-7">
                        <ul id="contact-info">
                                <li><address>
                                CODALS v/ Jesper Heltoft-Christensen<br />
                                    Hyldestub 30, Hørup<br />
                                    6470 Sydals<br />
                                    CVR: 40841830
                                    </address>
                                </li></ul>
                            </div>
                            <div className="col-lg-5">
                                <ul id="contact-info">
                                <li><FaEnvelope /> jesper@codals.dk</li>
                                <li><FaPhone /> +45 42704235</li>
                                <li><a href="https://www.linkedin.com/in/christensenjesper/" title="LinkedIn profil"><FaLinkedinIn /> LinkedIn-profil</a></li>
                                </ul>
                            
</div>
                          
                              
                        </div>
                    </div>
                </div>

               
                
                <SplashFooter />
            </main>
        </>
    )
}

export default Splash;