import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaDribbble, FaLinkedinIn } from "react-icons/fa";


const SplashFooter = () => {
    return (
        <footer className="footer-area splash-footer">
            <div className="container">
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                        <div className="footer-copyright">
                            <span className="copyright-text">© {new Date().getFullYear()} by CODALS</span>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <ul className="footer-social list-unstyled">
                        <li>DEVELOPED EXPERTISE</li>
                        </ul>
                        </div>
                        <div className="col-lg-3">
                        <div className="footer-bottom-link">
                            <ul className="list-unstyled">
                                <li>CVR 40841830</li>
                                {/* <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li> */}
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default SplashFooter;